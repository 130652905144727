<ts-tab-panel [panelTabs]="tabs" [(selectedTab)]="selectedTabId" type="primary" barType="standard" size="small"
    [selectTabOnClick]="true">
</ts-tab-panel>
<ng-container [ngSwitch]="selectedTabId">
    <div *ngSwitchCase="1" class="products-container">
        <!-- Product -->
        <div class="row">
            <h4 class="col title">{{'products.productTitle' | translate}}</h4>
        </div>
        <div class="row mt20">
            <!-- Codice Articolo -->
            <span class="col label secondary-color">{{'products.drawer.product-code' | translate}}</span>
            <!-- Descrizione Articolo -->
            <span class="col label secondary-color">{{'products.drawer.product-description' | translate}}</span>
        </div>
        <div class="row">
            <h4 class="col value">{{ product.code }}</h4>
            <h4 class="col value">{{ product.description }}</h4>
        </div>
        <div class="row mt15">
            <!-- Tipologia -->
            <span class="col label secondary-color">{{'products.drawer.product-type' | translate}}</span>
        </div>
        <div class="row">
            <h4 class="col value">{{ translateProductType(product.type) | translate }}</h4>
        </div>

        <!-- Unit -->
        <div *ngIf="product.ProductionUnit" class="row mt40">
            <!-- Unità di misura -->
            <h4 class="col title">{{'products.drawer.product-unit' | translate}}</h4>
        </div>
        <div *ngIf="product.ProductionUnit" class="row mt20">
            <span class="col label secondary-color">{{'products.drawer.product-unit' | translate}}</span>
            <!-- Descrizione unità di misura -->
            <span class="col label secondary-color">{{'products.drawer.product-unit-description' | translate}}</span>
        </div>
        <div *ngIf="product.ProductionUnit" class="row">
            <h4 class="col value">{{ product.ProductionUnit?.unit }}</h4>
            <h4 class="col value">{{ product.ProductionUnit?.description }}</h4>
        </div>
        <div class="row mt15">
            <!-- Decimali -->
            <span class="col label secondary-color">{{'products.drawer.product-unit-decimal' | translate}}</span>
        </div>
        <div class="row">
            <h4 class="col value">{{ product.ProductionUnit?.decimal ? product.ProductionUnit?.decimal : "-" }}</h4>
        </div>

        <!-- Family -->
        <div *ngIf="product.ProductsFamily" class="row mt40">
            <!-- Famiglia Articolo -->
            <h4 class="col title">{{'products.drawer.product-family' | translate}}</h4>
        </div>
        <div *ngIf="product.ProductsFamily" class="row mt20">
            <!-- Codice Famiglia -->
            <span class="col label secondary-color">{{'products.drawer.product-family-code' | translate}}</span>
            <!-- Descrizione famiglia -->
            <span class="col label secondary-color">{{'products.drawer.product-family-description' | translate}}</span>
        </div>
        <div *ngIf="product.ProductsFamily" class="row">
            <h4 class="col value">{{ product.ProductsFamily?.code }}</h4>
            <h4 class="col value">{{ product.ProductsFamily?.description }}</h4>
        </div>

        <!-- Resources -->
        <div class="row mt40">
            <!-- Risorse associate -->
            <h4 class="col title">{{'products.drawer.product-resources' | translate}}</h4>
        </div>
        <div class="row mt20">
            <span class="col label secondary-color">{{'products.drawer.product-resources-number' | translate}}</span>
        </div>
        <div class="row">
            <h4 class="col value">{{ product.Devices ? product.Devices?.length : 0 }}</h4>
        </div>

        <!-- Additional Info -->
        <div *ngIf="additionalInfos.length > 0" class="row mt40">
            <!-- Risorse associate -->
            <h4 class="col title">{{'products.drawer.product-additional-info' | translate}}</h4>
        </div>
        <ng-container *ngFor="let additionalInfo of additionalInfos">

            <div class="row mt20">
                <span class="col label secondary-color">{{'products.columns.product-description' | translate}}</span>
                <span class="col label secondary-color">{{'products.newEditProduct.value' | translate}}</span>
            </div>
            <div class="row">
                <h4 class="col value">{{ additionalInfo.description }}</h4>
                <h4 class="col value">{{ additionalInfo.value }}</h4>
            </div>
        </ng-container>

    </div>
    <div *ngSwitchCase="2" class="products-container">
        <!-- Resources -->
        <div class="row">
            <h4 class="col title">Risorse associate</h4>
        </div>
        <div class="mt10">
            <ts-accordion [allowMulti]="true">
                <ng-container *ngFor="let accordion of accordions">
                    <ts-accordion-panel [title]="accordion.title" [opened]="accordion.opened" (toggle)="onAccordionClick($event, accordion)">
                        <div class="el-container">
                            <div class="el-33 mt10" *ngIf="accordion.ProductsDevice.multiplier">
                                <span class="col label secondary-color">{{'products.drawer.product-resources-multi' | translate}}</span>
                                <h4 class="col value">{{ accordion.ProductsDevice.multiplier }} <span class="unit">{{ product.ProductionUnit.unit }}</span></h4>
                            </div>
                            <div class="el-33 mt10" *ngIf="accordion.ProductsDevice.scrapsMultiplier">
                                <span class="col label secondary-color">{{'products.drawer.product-resources-multi-scraps' | translate}}</span>
                                <h4 class="col value">{{ accordion.ProductsDevice.scrapsMultiplier }} <span class="unit">{{ product.ProductionUnit.unit }}</span></h4>
                            </div>
                            <div class="el-33 mt10" *ngIf="accordion.ProductsDevice.inputMultiplier">
                                <span class="col label secondary-color">{{'products.drawer.product-resources-multi-input' | translate}}</span>
                                <h4 class="col value">{{ accordion.ProductsDevice.inputMultiplier }} <span class="unit">{{ product.ProductionUnit.unit }}</span></h4>
                            </div>
                            <div class="el-33 mt10" *ngIf="accordion.ProductsDevice.targetSpeed">
                                <!-- Vel. target -->
                                <span class="col label secondary-color">{{'products.drawer.product-resources-vel-target' | translate}}</span>
                                <h4 class="col value">{{ accordion.ProductsDevice.targetSpeed }} <span class="unit">{{ product.ProductionUnit.unit }}/min</span></h4>
                            </div>
                            <div class="el-33 mt10" *ngIf="accordion.ProductsDevice.startThreshold">
                                <!-- Soglia start -->
                                <span class="col label secondary-color">{{'products.drawer.product-resources-threshold-start' | translate}}</span>
                                <h4 class="col value">{{ accordion.ProductsDevice.startThreshold }} <span class="unit">{{ product.ProductionUnit.unit }}</span></h4>
                            </div>
                            <div class="el-33 mt10" *ngIf="accordion.ProductsDevice.stopThreshold">
                                <!-- Soglia stop -->
                                <span class="col label secondary-color">{{'products.drawer.product-resources-threshold-end' | translate}}</span>
                                <h4 class="col value">{{ accordion.ProductsDevice.stopThreshold / 1000 }} <span class="unit">sec</span></h4>
                            </div>
                            <div class="el-33 mt10" *ngIf="accordion.ProductsDevice.divider">
                                <span class="col label secondary-color">{{'products.drawer.product-resources-divider' | translate}}</span>
                                <h4 class="col value">{{ accordion.ProductsDevice.divider }} <span class="unit">{{ product.ProductionUnit.unit }}</span></h4>
                            </div>
                            <div class="el-33 mt10" *ngIf="accordion.ProductsDevice.defaultQuantity">
                                <span class="col label secondary-color">{{'products.drawer.product-resources-default-quantity' | translate}}</span>
                                <h4 class="col value">{{ accordion.ProductsDevice.defaultQuantity }} <span class="unit">{{ product.ProductionUnit.unit }}</span></h4>
                            </div>
                        </div>
                    </ts-accordion-panel>
                </ng-container>
            </ts-accordion>
        </div>
    </div>
</ng-container>